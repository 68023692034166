<template>
  <b-card
      footer-class="text-muted"
  >
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mb-3">
      <b-col
          lg="12"
      >
        <h1>Verificar Tratamiento</h1>
      </b-col>
      <b-col
          lg="12"
      >
        <b-form @submit.prevent="updateLines(treatment)">
          <b-row>
            <b-col cols="6">
              <h4>Paciente: <strong>{{ name_pacient }}</strong></h4>
            </b-col>
            <b-col cols="6" v-if="number_picking">
              <h1>Salida:
                <strong>{{ number_picking }}</strong></h1>
            </b-col>
            <b-col cols="6">
              <h4>Sala: <strong>{{ place }}</strong></h4>
            </b-col>
            <b-col cols="6">
              <h4>Inicio: <strong>{{ start }}</strong></h4>
            </b-col>
            <b-col cols="6">
              <h4>Final: <strong>{{ end }}</strong></h4>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Tratamiento"
                  label-for="Nombre"
              >
                <b-form-input
                    v-model="treatment.name"
                    placeholder="Tratamiento"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-3" v-if="!validated_treatment">
              <table>
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Acciones</th>
                </tr>
                <tr v-for="(p, idx) in new_lines" :key="idx">
                  <td>
                    <v-select
                        v-model="p.name"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="product"
                    />
                  </td>
                  <td>
                    <b-form-group
                        label="Cantidad"
                        label-for="Nombre"
                    >
                      <b-form-input style="display: none"
                                    v-model="p.product_id"
                                    placeholder="Cantidad"
                      />
                      <b-form-input
                          v-model="p.qty"
                          placeholder="Cantidad"
                      />
                    </b-form-group>
                  </td>
                  <td v-if="!validated_treatment">
                    <b-row>
                      <b-col cols="6" v-if="!p.new_line">
                        <b-button
                            @click="addRow(idx)"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Agregar
                        </b-button>
                      </b-col>
                      <b-col cols="6" v-else>
                        <b-button
                            @click="removeLine(idx)"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Borrar
                        </b-button>
                      </b-col>
                    </b-row>


                  </td>
                </tr>
              </table>
            </b-col>

            <b-col cols="12" class="mt-3" v-else>
              <table id="table-pdf">
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th v-if="!validated_treatment">Acciones</th>
                </tr>
                <tr v-for="(p, idx) in new_lines" :key="idx">
                  <td>
                    <v-select
                        v-model="p.name"
                        disabled
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="product"
                    />
                  </td>
                  <td>
                    <b-form-group
                        label="Cantidad"
                        label-for="Nombre"
                    >
                      <b-form-input style="display: none"
                                    v-model="p.product_id"
                                    placeholder="Cantidad"
                      />
                      <b-form-input
                          disabled="disabled"
                          v-model="p.qty"
                          placeholder="Cantidad"
                      />
                    </b-form-group>
                  </td>
                  <td v-if="!validated_treatment">
                    <b-row>
                      <b-col cols="6" v-if="!p.new_line">
                        <b-button
                            @click="addRow(idx)"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Agregar
                        </b-button>
                      </b-col>
                      <b-col cols="6" v-else>
                        <b-button
                            @click="removeLine(idx)"
                            style="width: 100%"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                        >
                          Borrar
                        </b-button>
                      </b-col>
                    </b-row>


                  </td>
                </tr>
              </table>
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12" class="mt-1" v-if="!validated_treatment">
              <b-button v-if="!checkChemo"
                  style="width: 100%"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
              >
                Guardar
              </b-button>

            </b-col>
            <b-col cols="12" class="mt-1" >
              <div align="right">
                <b-button
                    @click="exportPDF"
                    style="width: 200px"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir
                </b-button>
              </div>

            </b-col>

          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm, BOverlay

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import {mapGetters, mapActions} from "vuex";
import {VueAutosuggest} from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import {createTimeReal} from "@/sockets/socket";
export default {
  name: "DetailTreatment",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar, flatPickr, vSelect, VueAutosuggest, BOverlay
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      validated_treatment: false,
      checkChemo: false,
      number_picking:null,
      select_product: {id: '', value: ''},
      new_lines: [],
      product: [],
      name_pacient: '',
      pricelist_patient:null,
      id_patient:null,
      show: false,
      start: '',
      end: '',
      place: '',
      id: null,
      treatment: {
        name: '',

        lines: [],

      }
    }
  },
  props:{
    id_treatment_props:Number
  },
  async created() {
    await this.searchTreatment(this.id_treatment_props)
    await this.findProducts()
    await this.patientVerify()
  },
  methods: {
    ...mapActions('appointment', ['findAppointmentUserOne']),
    ...mapActions('patient', ['findPricelistPatient']),
    ...mapActions('treatment', ['findProduct', 'updateTreatment', 'getOneTreatment','getStockPicking']),
    async patientVerify() {
      const response = await this.findPricelistPatient(this.id_patient)
      this.pricelist_patient = response.name

    },
    async findProducts() {
      const response = await this.findProduct();
      for (const a of response) {
        const de = a.default_code ?  '('+a.default_code+')' : ''
        this.product.push({
          id: a.id,
          value: a.name+ ' ' + de
        })
      }
    },

    async linesProducts(data) {
      for (const a of data) {
        this.new_lines.push({
          product_id: a.product_id,
          name: a.ProductTemplate.name,
          qty: a.qty,
          new_line: true,
        })
      }
      if (this.validated_treatment) {

      } else {
        this.new_lines.push({
          product_id: '',
          name: '',
          qty: 0,
          new_line: false
        })
      }

    },
    async searchTreatment(id) {
      const response = await this.getOneTreatment(id);
      this.id = parseInt(id)
      this.name_pacient = response.ResPartner.name
      this.id_patient = response.ResPartner.id
      this.treatment.name = response.ClinicTreatment.name
      this.start = this.resHoursOdoo(response.app_dt_start)
      this.end = this.resHoursOdoo(response.app_dt_stop)
      this.place = response.ResPartnerAppointment.name
      if (response.ClinicTreatment.treatment_validated) {
        this.validated_treatment = true
        const response_pi = await this.getStockPicking(this.id);
        if (response){
          const names = response_pi.map(item => item.name).join(', ');
          this.number_picking = names
        }else{
          this.number_picking = null
        }
      } else {
        this.validated_treatment = false
      }
      await this.linesProducts(response.ClinicTreatment.ClinicTreatmentLines);
      console.log(response.ResPartner)
      if (response.ResPartner.chemo_suspension){
        this.checkChemo = true
        this.$swal({
          icon: 'warning',
          title: `Quimioterapia supendida:  ${response.ResPartner.reason_suspension} `,
          text: `El paciente esta suspendido por: ${response.ResPartner.comments_suspension ?  response.ResPartner.comments_suspension:'Quimioterapia Suspendida!'} `,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

      }else{
        this.checkChemo = false
      }
    },
    addRow(id) {
      this.new_lines[id].product_id = this.new_lines[id].name.id
      this.new_lines[id].name = this.new_lines[id].name.value
      this.new_lines[id].new_line = true
      this.new_lines.push({
        product_id: '',
        name: '',
        qty: 0,
        new_line: false,
      })
    },
    removeLine(id) {
      this.new_lines.splice(id, 1)
    },

    async updateLines() {
      this.show = true
      this.start = true
      let lines = []
      for (const a of this.new_lines) {
        if (a.product_id && a.qty) {
          lines.push({
            product_id: a.product_id,
            qty: a.qty
          })
        }
      }
      if (lines.length <= 0){
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error no puede mandar lineas vacias',
            icon: 'error',
            variant: 'warning',
          },
        });
        return
      }
      this.treatment.lines = lines

      const response = await this.updateTreatment({id: this.id, data: this.treatment});
      if (response) {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tratamiento editado',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await createTimeReal(true)
        this.start = false
        await this.$root.$emit('closeComponent')
      } else {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al editar tratamiento',
            icon: 'error',
            variant: 'warning',
          },
        });
        this.start = false
      }
    },
    exportPDF() {

      const date = this.start.split(" ")
      const date2 = this.end.split(" ")
      var doc = new jsPDF('p', 'pt', 'a4');
      const count_lines = this.new_lines.length

      doc.text(`Tratamiento`, 40, 40);
      if (this.number_picking){
        doc.setFontSize(10);
        doc.text(`Salida: ${this.number_picking}`, 150, 40);
      }else{

      }
      doc.setFontSize(15);
      doc.text(`Paciente: ${this.name_pacient}`, 40, 70);
      doc.text(`Tipo de paciente: ${this.pricelist_patient}`, 40, 100);
      doc.text(`Fecha: ${date[0]}`, 40, 130);
      doc.text(`Horario: ${date[1]} - ${date2[1]}`, 200, 130);
      doc.autoTable({
        columns: [
          {header: 'Medicamento', dataKey: 'name'},
          {header: 'Lote', dataKey: ''},
          {header: 'Caducidad', dataKey: 'a'},
          {header: 'Cantidad', dataKey: 'qty'},
        ],
        body: this.new_lines,
        margin: {top: 150},
      })
      let finalY = doc.autoTable.previous.finalY
      if (finalY >= 600){
        doc.addPage();
        doc.text(`Extras:____________________________________________________`, 40,   60);
        doc.text(`__________________________________________________________`, 40,  100);
        doc.text(`__________________________________________________________`, 40,  140);
        doc.text(`Nombre:____________________`, 40,  220);
        doc.text(`Firma:______________________`, 40,  260);
        doc.text(`Nombre:____________________`, 305,  220);
        doc.text(`Firma:______________________`, 305,  260);
      }else{
        doc.text(`Extras:____________________________________________________`, 40,  finalY +  40);
        doc.text(`__________________________________________________________`, 40, finalY + 80);
        doc.text(`__________________________________________________________`, 40, finalY + 120);
        doc.text(`Nombre:____________________`, 40, finalY + 220);
        doc.text(`Firma:______________________`, 40, finalY + 260);
        doc.text(`Nombre:____________________`, 305, finalY + 220);
        doc.text(`Firma:______________________`, 305, finalY + 260);
      }

      doc.save(`${this.name_pacient}-${date[0]}.pdf`);
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  }
}
</script>

<style lang="scss">
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}

@import '@core/scss/vue/libs/vue-select.scss';
.vs__clear {
  display: none !important;
}
</style>
